<template>
    <div class="roomPrice">
      <iframe scrolling: no
        :src="src"
        frameborder="0"
      ></iframe>
    </div>
  </template>
  
  <script>
  import urls from "../../utils/baseUrl2.js";
  import { hotelList } from "../../api/hotelList";
  import { roomDetails, roomList, roomPriceList } from "../../api/room";
  export default {
    name: "roomPrice",
    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        src: `${urls.baseUrl}roomPrice/roomLinkingPriceIframe.html?roomId=${this.id}&isDevelopment=${urls.baseUrl}&connectId=${this.$route.query.connectId}`,
      };
    },
    mounted() {
        console.log(this.id)
    },
    created() {},
    methods: {},
  };
  </script>
  <style scoped lang='less'>
  .roomPrice {
    height: 860px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  </style>